import * as angular from "angular";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import { Set, Object } from "es6-shim";
import { $uiRouter } from "Bootstrap/angular";
import { SamskipNotify } from "Services";
import { ArrayUtilities, ModalUtilities } from "Utilities";
import NotificationManagerService from "NotificationManager/NotificationManagerService";

const editorActions = {
  CREATE: "CREATE",
  EDIT: "EDIT"
};
const editorDocTypes = {
  TEMPLATE: "TEMPLATE",
  VERSION: "VERSION"
};

function createTemplateOrVersionController(parent: any) {
  const $state: StateService = $uiRouter.stateService;
  let vm = this;

  let editorAction: any;
  let editorDocType: any;

  function initVM() {
    vm = Object.assign(vm, parent);

    vm.docForm = {};

    // Temporary solution for us to figure out what we are doing in the
    // editor (creating or editing) and which kind of creating/editing
    // (template or version).
    if ($state.is("notificationmanager.createtemplate")) {
      editorAction = editorActions.CREATE;
      editorDocType = editorDocTypes.TEMPLATE;
      vm.header = "LABEL_CREATE_NOTIFICATION_TEMPLATE";
      vm.submitLabel = "LABEL_SAVE_NOTIFICATION_TEMPLATE";
      const template = <NotificationTemplate>{};
      template.TypeID = vm.TypeID;
      vm.doc = template;
    } else if ($state.is("notificationmanager.createversion")) {
      editorAction = editorActions.CREATE;
      editorDocType = editorDocTypes.VERSION;
      vm.header = "LABEL_CREATE_NOTIFICATION_VERSION";
      vm.submitLabel = "LABEL_SAVE_NOTIFICATION_VERSION";
      const version = <NotificationVersion>{};
      if (parent) {
        version.TemplateID = parent.ID;
        version.Title = parent.Title;
        version.Description = parent.Description;
        version.Content = parent.Content;
        version.ContentShort = parent.ContentShort;
      }
      vm.doc = version;
    }
  }

  /**
   * Inserts an attribute into an element.
   * @param  {string} targetElement Id of the targetElement
   * @param  {object} attribute     Notification attribute as returned by
   *                                NotificationManagerService.getRequiredAttributesForType()
   */
  vm.insertAttribute = function insertAttribute(
    targetElement: any,
    attribute: any
  ) {
    const elem: any = document.getElementById(targetElement);
    const cursorPos: any = getCursorPosition(elem);
    const origValue = elem.value;
    const addedValue = `{{${attribute.Name}}} `;

    if (typeof cursorPos === "object") {
      // getCursorPosition() returned an object with start & end properties
      // so we replace the selected text
      elem.value = [
        origValue.slice(0, cursorPos.start),
        addedValue,
        origValue.slice(cursorPos.end)
      ].join("");
      // Move the cursor to the back of the inserted value
      setCursorPosition(elem, cursorPos.start + addedValue.length);
    } else {
      // Insert the attribute at the requested position
      elem.value = [
        origValue.slice(0, cursorPos),
        addedValue,
        origValue.slice(cursorPos)
      ].join("");
      // Move the cursor to the back of the inserted value
      setCursorPosition(elem, cursorPos + addedValue.length);
    }
    // focus the element
    elem.focus();

    // Trigger an new $digest cycle so that the model associated with
    // the element via ng-model is updated (without having to depend on
    // $scope.$digest or $scope.apply explicitly).
    angular.element(elem).triggerHandler("input");
  };

  /**
   * Gets the location of the input cursor in the given HTMLElement.
   * If no text is selected it returns index of the character that follows
   * the input cursor. If however there is some text selected and the
   * browser supports both TextArea.selectionStart and TextArea.selectionEnd
   * the start-, and end-indices are returned in an object.
   * @param  {HTMLElement} elem   HTMLElement
   * @return {Number|object}      Returns the index of the character that
   *                                      follows the input cursor.
   */
  function getCursorPosition(elem: any): {} | undefined {
    if (elem.selectionStart || elem.selectionStart === "0") {
      // Other browsers
      return {
        start: elem.selectionStart,
        end: elem.selectionEnd
      };
    }
  }

  /**
   * Sets the location of the input cursor in the given HTMLElement.
   * @param {HTMLElement} elem     HTMLElement.
   * @param {Number}      position The index to set the input cursor's position as.
   */
  function setCursorPosition(elem: any, position: any) {
    const scrollTop = elem.scrollTop;
    if (elem.createTextRange) {
      // IE support
      const range = elem.createTextRange();
      range.collapse(true);
      range.moveEnd("character", position);
      range.moveStart("character", position);
      range.select();
    } else if (elem.setSelectionRange) {
      // Other browsers
      elem.focus();
      elem.setSelectionRange(position, position);
    }
    elem.scrollTop = scrollTop;
  }

  /**
   * Checks if all of the attributes that are required for a given type
   * have been added to either 'Content' or 'ContentShort'.
   * @return {bool} Returns true if all of the required attributes have
   *                        been added to 'Content' or 'ContentShort'.
   */
  function allAttributesSet() {
    // Get a list attribute names that have been added to Content
    // and/or ContentShort
    const contentAttrs: any = NotificationManagerService.extractAttributesFromString(
      vm.doc.Content
    );
    const contentShortAttrs: any = NotificationManagerService.extractAttributesFromString(
      vm.doc.ContentShort
    );

    const addedAttrs: any = ArrayUtilities.union(
      contentAttrs,
      contentShortAttrs
    ).sort();

    // Get a list of the required attributes names.
    const requiredOrdered = (vm.RequiredAttributes || []).map(
      (at: any) => at.Name
    );

    // Check if there's a difference between the added and required attributes
    return ArrayUtilities.difference(requiredOrdered, addedAttrs).length === 0;
  }

  /**
   * Called when the user hits the submit button to save (create) a new
   * notification template or version. If some of the required attributes
   * for the notification type have not been set, a confirmation dialog is
   * shown. If all attributes have been set (or the user confirms), the
   * new template/version is sent to the database.
   */
  vm.submit = function submit() {
    // Do nothing if the form is in an invalid state.
    if (vm.docForm.$invalid) {
      return false;
    }

    if (editorAction !== editorActions.CREATE) {
      throw new Error(`editorAction: "${editorAction}" is not supported`);
    }

    // If all of the attributes that are required for the ancestral
    // notification type have been set for the template/version we post
    // the new template/version to server an wait for a response.
    // Otherwise, the user is prompted for confirmation befeore proceeding.
    if (allAttributesSet()) {
      // No confirmation needed when all attributes set.
      saveDocType();
    } else {
      // TODO: Move this out to a template with translations.
      const modalInstance = ModalUtilities.showSimpleModal(
        `<div class="modal-header">
                    <h3 class="modal-title">Ertu viss?</h3>
                </div>
                <div class="modal-body" style="padding: 15px">
                    <p>Ekki er búið að staðsetja allar breytur í textasniðmátinu. <br>
                       Ertu viss um að þú viljir vista?</p>
                    <small> Þær breytur sem ekki voru fylltar út að þessu sinni þarf að gefa gildi þegar
                            tilkynning er send.
                    </small>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button"
                        ng-click="$close({saveConfirmed: true})" translate="LABEL_SAVE"></button>
                    <button class="btn btn-link" type="button" ng-click="$dismiss()" translate="LABEL_CANCEL"></button>
                </div>`
      );

      modalInstance.then((result: any) => {
        if (result.saveConfirmed === true) {
          saveDocType();
        }
      });
    }

    return undefined;
  };

  /**
   * Convenience method to minimize noise in the HTML that checks for the
   * validity of a form-control and returns a css class-name to apply to
   * that form-control based on its state.
   * @param  {string} formControl     Same as the element's a form input's
   *                                  name attribute.
   * @return {string}                 Returns the css class-name to apply.
   */
  vm.inputClass = function inputClass(formControl: string) {
    const inputModel = vm.docForm[formControl];
    if (inputModel && inputModel.$touched && inputModel.$invalid) {
      return "has-error";
    }
    return "";
  };

  /**
   * Sends a new notification template/version to server. If the request
   * is successful, we transition to the appropriate state to view the
   * created template/version.
   */
  function saveDocType() {
    if (editorAction === editorActions.CREATE) {
      if (editorDocType === editorDocTypes.TEMPLATE) {
        // Post the new template to server and transition to a new
        // state on successful template creation
        NotificationManagerService.createTemplate(vm.doc).then(
          (data: any) => {
            $state.go("notificationmanager.template", {
              templateId: data
            });
          },
          (data: any) => {
            SamskipNotify.displayWarning(data);
          }
        );
      } else if (editorDocType === editorDocTypes.VERSION) {
        // Post the new version to server and transition to a new
        // state on successful version creation
        NotificationManagerService.createVersion(vm.doc).then(
          () => {
            $state.go("notificationmanager.template", {
              templateId: vm.doc.TemplateID
            });
          },
          (error: any) => {
            SamskipNotify.displayWarning(error);
          }
        );
      }
    }
  }

  initVM();
}

createTemplateOrVersionController.$inject = ["parent"];
export default createTemplateOrVersionController;
