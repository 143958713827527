import * as moment from "moment";
import { Object } from "es6-shim";
import { SamskipNotify } from "Services";
import NotificationManagerService from "NotificationManager/NotificationManagerService";
import { NOTIFICATION_STATUS } from "Constants/NotificationConstants";
import { UtilityFunctions } from "Utilities";

function templateViewController(
  $sce: any,
  baseTemplate: any,
  notifications: any
) {
  let vm = this;

  // Icons used when displaying notification statuses
  const icons = {
    warning: "fa-exclamation-triangle text-warning",
    error: "fa-exclamation-circle text-danger",
    success: "fa-check-circle text-success",
    processing: "fa-spinner fa-spin fa-pulse",
    pending: "fa-clock-o"
  };

  // Contextual classes from bootstrap to alter presentation of
  // notifications depending on their status
  const contextualBg = {
    warning: "bg-warning",
    error: "bg-danger"
  };

  function init() {
    // Merge the notification template and vm so that we don't have to
    // assign each property manually.
    vm = Object.assign(vm, baseTemplate);

    // Add the list of notifications created from the template to the VM
    vm.notifications = notifications;

    // Get the status for each notification (for display)
    vm.notifications.forEach((it: any) => {
      it._batchStatus = NotificationManagerService.getNotificationStatus(it);
      it._sendSmsIcon = NotificationManagerService.asBool(it.SendSMS)
        ? "fa-check-square-o"
        : "fa-times";
      transformStatus(it);
    });
  }

  vm.formatDate = function formatDate(date: any, format: string): string {
    if (!date) {
      return "N/A";
    }
    if (!format) {
      return moment(date).format("DD.MM.YY");
    }
    return moment(date).format(format);
  };

  vm.copyVersion = function copyVersion(): void {
    console.error("not implemented: copyVersion");
  };

  /**
   * Adds a view 'readonly' values to the notifications depending on their
   * status. E.g. when a notification has been processing for an unusually
   * long time, we display a warning icon. When there was an error we
   * display another icon, etc.
   */
  function transformStatus(notification: any) {
    switch (notification._batchStatus) {
      case NOTIFICATION_STATUS.BatchDelayed:
        batchDelayedHandler(notification);
        break;
      case NOTIFICATION_STATUS.BatchFailed:
        batchFailedHandler(notification);
        break;
      case NOTIFICATION_STATUS.BatchFinished:
        batchFinishedHandler(notification);
        break;
      case NOTIFICATION_STATUS.BatchPending:
        batchPendingHandler(notification);
        break;
      case NOTIFICATION_STATUS.BatchProcessing:
        batchProcessingHandler(notification);
        break;
      default:
        break;
    }
    notification._statusDate = vm.formatDate(
      notification._statusDate,
      "DD.MM.YY HH:mm"
    );
  }

  function batchDelayedHandler(notification: any) {
    notification._statusIcon = icons.warning;
    notification._statusContext = contextualBg.warning;
    notification._statusDate = notification.DateSendAfter;
    notification._statusHeader = "LABEL_DATE_SEND_STARTED";
  }

  function batchFailedHandler(notification: any) {
    notification._statusIcon = icons.error;
    notification._statusContext = contextualBg.error;
    notification._statusDate = notification.DateSendAfter;
    notification._statusHeader = "LABEL_DATE_SEND_FAILED";
  }

  function batchFinishedHandler(notification: any) {
    notification._statusIcon = icons.success;
    notification._statusDate = notification.FinishedSend;
    notification._statusHeader = "LABEL_DATE_SEND_FINISHED";
  }

  function batchPendingHandler(notification: any) {
    notification._statusIcon = icons.pending;
    notification._statusDate = notification.DateSendAfter;
    notification._statusHeader = "LABEL_DATE_SEND_AFTER";
  }

  function batchProcessingHandler(notification: any) {
    notification._statusIcon = icons.processing;
    notification._statusDate = notification.DateSendAfter;
    notification._statusHeader = "LABEL_DATE_SEND_STARTED";
  }

  init();
}

templateViewController.$inject = ["$sce", "baseTemplate", "notifications"];
export default templateViewController;
