import * as angular from "angular";

// tslint:disable: max-line-length

import NotificationManagerController from "NotificationManager/NotificationManagerController";
import NotificationManagerService from "NotificationManager/NotificationManagerService";
import TemplateViewController from "NotificationManager/TemplateViewController";
import CreateTemplateOrVersionController from "NotificationManager/CreateTemplateOrVersionController";
import * as notificationManagerTemplate from "NotificationManager/partials/notificationManager.html";
import * as notificationManagerDefaultTemplate from "NotificationManager/partials/default.html";
import * as notificationManagerViewTemplate from "NotificationManager/partials/templateview.tpl.html";
import * as notificationManagerCreateTemplateOrVersionTemplate from "NotificationManager/partials/createTemplateOrVersion.html";
import CreateNotificationController from "NotificationManager/createNotification/CreateNotificationController";
import * as notificationManagerCreateNotification from "NotificationManager/createNotification/createNotification.html";
import { USER_ROLES } from "Constants/UserRoles";
import { $uiRouter } from "Bootstrap/angular";
import { Transition } from "@uirouter/core";

export const MAIN_STATE: string = "notificationmanager";

class NotificationManager {
  constructor() {
    const routes: any[] = [
      // Abstract state (will never be routed to)
      {
        name: `${MAIN_STATE}`,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        abstract: true,
        template: `${notificationManagerTemplate}`,
        controller: NotificationManagerController,
        controllerAs: "$ctrl",
        resolve: {
          notificationTypes: () => {
            return NotificationManagerService.getNotificationTypes();
          }
        },
        data: {
          roles: [USER_ROLES.NOTIFICATIONMANAGER]
        }
      },
      {
        name: `${MAIN_STATE}.default`,
        url: "",
        template: `${notificationManagerDefaultTemplate}`,
        controller: NotificationManagerController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.NOTIFICATIONMANAGER]
        }
      },
      {
        name: `${MAIN_STATE}.template`,
        url: "/templates/:templateId",
        template: `${notificationManagerViewTemplate}`,
        controller: TemplateViewController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.NOTIFICATIONMANAGER]
        },
        resolve: {
          baseTemplate: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationManagerService.getTemplate(
                toStateParams.templateId
              );
            }
          ],
          notifications: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationManagerService.getNotificationsByTemplateId(
                toStateParams.templateId
              );
            }
          ]
        }
      },
      {
        name: `${MAIN_STATE}.createtemplate`,
        url: "/createtemplate/:typeId",
        template: `${notificationManagerCreateTemplateOrVersionTemplate}`,
        controller: CreateTemplateOrVersionController,
        controllerAs: "$ctrl",
        resolve: {
          parent: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationManagerService.getNotificationTypes(
                toStateParams.typeId
              );
            }
          ]
        }
      },
      {
        name: `${MAIN_STATE}.createversion`,
        url: "/createversion/:templateId",
        template: `${notificationManagerCreateTemplateOrVersionTemplate}`,
        controller: CreateTemplateOrVersionController,
        controllerAs: "$ctrl",
        resolve: {
          parent: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationManagerService.getTemplate(
                toStateParams.templateId
              );
            }
          ]
        }
      },
      {
        name: `${MAIN_STATE}.createnotification`,
        url: "/versions/:versionId/notifications/create",
        template: `${notificationManagerCreateNotification}`,
        controller: CreateNotificationController,
        controllerAs: "$ctrl",
        data: {
          roles: [USER_ROLES.NOTIFICATIONMANAGER]
        },
        resolve: {
          templates: [
            "$transition$",
            ($transition$: Transition) => {
              return NotificationManagerService.getNotificationsHTMLTemplates();
            }
          ],
          notificationVersion: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationManagerService.getNotificationVersion(
                toStateParams.versionId
              );
            }
          ]
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(NotificationManager);
