import * as moment from "moment";
import { $sce, $templateCache } from "ngimport";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import * as dateTimeTemplate from "./dateTimeTemplate.html";
import NotificationManagerService from "NotificationManager/NotificationManagerService";
import { SamskipNotify } from "Services";
import { ModalUtilities } from "Utilities";
import { $uiRouter } from "Bootstrap/angular";

function createNotificationController(
  $scope: any,
  templates: any[],
  notificationVersion: any,
  $transition$: Transition
) {
  const vm = this;
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  // Cache the date time template which is used in popover
  $templateCache.put("dateTimeTemplate.html", dateTimeTemplate);

  vm.init = function init() {
    vm.version = notificationVersion;
    vm.templates = templates;
    vm.title = notificationVersion.Title;
    vm.attributeValues = {};
    vm.SendEmail = true;
    const contentAttributes = NotificationManagerService.extractAttributesFromString(
      vm.version.Content
    );

    // Get logic variables (the ones that are not in contentAttributes)
    vm.logicVariables = vm.version.Attributes.filter((el: any) => {
      return contentAttributes.indexOf(el.Name) === -1;
    });

    vm.notificationContent = notificationVersion.Content;
    vm.notificationContentShort = notificationVersion.ContentShort;
    vm.senddate = moment().format("DD.MM.YYYY HH:mm");
    vm.minDate = new Date();
  };

  vm.minDate = new Date();
  vm.dateTimeOpen = false;
  // To close the timepicker from the directive.
  $scope.$on("createNotificationDateTimePicker", () => {
    vm.dateTimeOpen = false;
  });

  vm.onSendDateSet = function onSendDateSet(attr: any, value: any) {
    vm.senddate = value;
    vm.dateTimeOpen = false;
  };

  vm.closeDatePicker = function closeDatePicker() {
    vm.dateTimeOpen = false;
  };

  vm.submit = function submit() {
    if (!vm.template && vm.SendEmail) {
      SamskipNotify.displayWarning("TEXT_MISSING_EMAIL_TEMPLATE");
      return;
    }

    // Force confirmation from the user to save the notification, and
    // schedule sending for it after the selected date.
    // TODO: Move this out to a template with translations.
    const modalInstance = ModalUtilities.showSimpleModal(
      `<div class="modal-header">
                <h3 class="modal-title">Ertu viss?</h3>
            </div>
            <div class="modal-body" style="padding: 15px">
                <p>Ertu viss um að þú viljir vista þessa tilkynningu þannig að hún sendist út á valinni dagsetningu?</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button"
                    ng-click="$close({saveConfirmed: true})" translate="LABEL_SAVE"></button>
                <button class="btn btn-link" type="button" ng-click="$dismiss()" translate="LABEL_CANCEL"></button>
            </div>`
    );

    modalInstance.then((closeResult: any) => {
      if (closeResult.saveConfirmed === true) {
        saveNotification();
      }
    });
  };

  /**
   * Makes the actual call to server to save the new notification to db
   * and transitions to a state where the new notification is visible.
   */
  function saveNotification() {
    const title = vm.title;

    const notificationVM = {
      VersionID: currentStateParams.versionId,
      TemplateLayoutID: vm.template ? vm.template.ID : null,
      Title: title,
      Attributes: JSON.stringify(vm.attributeValues),
      DateSendAfter: moment(vm.senddate, "DD.MM.YYYY HH:mm").format(),
      SendSMS: vm.SendSMS || false,
      SendEmail: vm.SendEmail
    };

    NotificationManagerService.createNotification(notificationVM).then(() => {
      $state.go("notificationmanager.template", {
        templateId: vm.version.TemplateID
      });
    });
  }

  vm.init();
}

createNotificationController.$inject = [
  "$scope",
  "templates",
  "notificationVersion",
  "$transition$"
];
export default createNotificationController;
