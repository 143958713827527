import * as moment from "moment";
import { NOTIFICATION_STATUS } from "Constants/NotificationConstants";
import SamskipResource from "SamskipResource";

class NotificationManagerService {
  /**
   * Helper method which explicitly maps a few truthy values of our choosing.
   * TODO: Maybe this should be moved to UtilityFunctions if there isn't
   *     already some similar function.
   * @param  {any}    testValue   Value to translate to boolean
   * @return {bool}               Returns true if the testvalue explicitly
   *                                maps to one of our mapped 'truthy' values
   */
  asBool = (testValue?: number | string): boolean => {
    if (!isNaN(Number(testValue))) {
      return Number(testValue) !== 0;
    }
    if (typeof testValue === "string") {
      const testValueLower = testValue.toLowerCase();
      switch (testValueLower) {
        case "y":
        case "já":
        case "yes":
        case "true":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  /**
   * Gets details for a specific notifcation such as when sent, by whom, when
   * the sending completed, who recieved the notifications, etc.
   * @param  {Number} notificationId      Id of the notification to get details for.
   * @return {Promise}                    Returns a Promise, resolved with notification details.
   */
  getNotification = (
    notificationId: number
  ): SamskipPromise<NotificationCompiled> => {
    return SamskipResource.get<NotificationCompiled>(
      "NotificationAPI",
      `manager/notifications/${notificationId}`
    );
  };

  /**
   *
   * @param  {Object}     CreateNotificationVM      a CreateNotificationVM object.
   * @return {Promise}                              Returns a promise
   */
  createNotification = (CreateNotificationVM: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "NotificationAPI",
      "manager/notifications/",
      "",
      CreateNotificationVM
    );
  };

  /**
   * Gets a list of notifications that have been sent directly using the
   * specified templateId. I.e. it does not include the notifications that
   * were sent using a version derived from the template.
   * @param  {Number} templateId      Id of the template to get derived notifications.
   * @return {Promis}                 Returns a Promise, resolved with a list of notifications.
   */
  getNotificationsByTemplateId = (
    templateId: number
  ): SamskipPromise<NotificationCompiled[]> => {
    return SamskipResource.get<NotificationCompiled[]>(
      "NotificationAPI",
      `manager/templates/${templateId}/notifications`
    );
  };

  /**
   * Gets a list of notifications sent using a specific notification version.
   * @param  {Number} versionId       Id of the notification version to get
   *                                  derived notifications for.
   * @return {Promis}                 Returns a Promise, resolved with a list of notifications.
   */
  getNotificationsByVersionId = (
    versionId: number
  ): SamskipPromise<Notification[]> => {
    return SamskipResource.get<Notification[]>(
      "NotificationAPI",
      `manager/versions/${versionId}/notifications`
    );
  };

  getNotificationVersion = (
    versionId: number
  ): SamskipPromise<NotificationVersionAttributeViewModel> => {
    return SamskipResource.get<NotificationVersionAttributeViewModel>(
      "NotificationAPI",
      `manager/versions/${versionId}`
    );
  };

  getNotificationsHTMLTemplates = (): SamskipPromise<
    NotificationHTMLTemplate[]
  > => {
    return SamskipResource.get<NotificationHTMLTemplate[]>(
      "NotificationAPI",
      "manager/htmltemplates"
    );
  };

  getNotificationTypes = (
    typeId?: number
  ): SamskipPromise<NotificationTypeVersion> => {
    let typeIdParam = "";
    if (typeId !== undefined && isFinite(typeId)) {
      typeIdParam = `/${typeId}`;
    }
    return SamskipResource.get<NotificationTypeVersion>(
      "NotificationAPI",
      `manager/types${typeIdParam}`
    );
  };

  /**
   * Gets a list of all notification templates.
   * @return {Promise} Returns a promise.
   */
  getTemplates = (): SamskipPromise<NotificationTemplateViewModel[]> => {
    return SamskipResource.get<NotificationTemplateViewModel[]>(
      "NotificationAPI",
      "manager/templates/"
    );
  };

  /**
   * Gets specific template and a list of derived versions.
   * @param  {Number} templateId      Id of the template to get.
   * @return {Promise}
   */
  getTemplate = (
    templateId: number
  ): SamskipPromise<NotificationTemplateViewModel> => {
    const templateIdNumber = Number(templateId);
    return SamskipResource.get<NotificationTemplateViewModel>(
      "NotificationAPI",
      `manager/templates/${templateIdNumber}`
    );
  };

  /**
   * Creates a new template.
   * @param  {object} newTemplate Object containing info for the new template
   * @return {Promise}            Returns a promise resolved when the
   *                                       notification has been created.
   */
  createTemplate = (newTemplate: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "NotificationAPI",
      "manager/templates",
      "",
      newTemplate
    );
  };

  /**
   * Creates a new notification version.
   * @param  {object} newVersion  Object containing info for the new version.
   * @return {Promise}            Returns a Promise.
   */
  createVersion = (newVersion: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "NotificationAPI",
      "manager/versions",
      "",
      newVersion
    );
  };

  /**
   * Replaces variable placeholders in the template.Content and
   * template.ContentShort with those defined in the attributes map.
   * @param  {object} template   Either a NotificationTemplate or a
   *                             NotificationVersion. In any case, the
   *                             object must have set Content and (optionally)
   *                             ContentShort properties.
   * @param  {object|Json string} attributes Map of values where the key is the
   *                                 attribute name to be replaced with
   *                                 the value in Content and ContentShort.
   * @return {string}            Returns a new object with contentInterpolated,
   *                                     and contentShortInterpolated properties.
   */
  interPolateContent = (template: any, attributes: any) => {
    let interpContent = template.Content;
    let interpContentShort = template.ContentShort;

    const attributeObj =
      typeof attributes === "string" ? JSON.parse(attributes) : attributes;

    for (const key in attributeObj) {
      const pattrn = new RegExp(`{{${key}}}`, "g");

      interpContent = interpContent.replace(pattrn, attributeObj[key]);
      interpContentShort = interpContentShort.replace(
        pattrn,
        attributeObj[key]
      );
    }

    return {
      Content: interpContent,
      ContentShort: interpContentShort
    };
  };

  /**
   * Extract the names of attributes enclosed in double curly braces
   * from the input string.
   * @param  {string} contentString   The string to extract attribute names from
   * @return {array}                  Returns the names of found attributes,
   *                                          empty array if non found.
   */
  extractAttributesFromString = (inputStr: string): string[] => {
    const pattrn = new RegExp("{{([A-Z]+[_A-Z]*)}}", "g");
    let matchArr;
    const result = [];

    if (typeof inputStr === "string" && inputStr.length > 0) {
      do {
        matchArr = pattrn.exec(inputStr);
        if (matchArr) {
          result.push(matchArr[1]);
        }
      } while (matchArr);
    }

    return result;
  };

  /**
   * Helper method that calculates the status of a notification.
   * @param  {object} notification Notification object.
   * @return {string}              Returns one of the status codes from the
   *                                       NOTIFICATION_STATUS constant
   */
  getNotificationStatus = (notification: any) => {
    const maxProcessingMinutes = 3;
    let status = NOTIFICATION_STATUS.BatchPending;

    if (this.asBool(notification.Error)) {
      status = NOTIFICATION_STATUS.BatchFailed;
    } else if (notification.FinishedSend) {
      status = NOTIFICATION_STATUS.BatchFinished;
    } else if (this.asBool(notification.Processing)) {
      const sendAfter = moment(notification.DateSendAfter);
      const processingTime = sendAfter.isValid()
        ? Math.abs(sendAfter.diff(moment(), "m"))
        : 0;

      if (processingTime >= maxProcessingMinutes) {
        status = NOTIFICATION_STATUS.BatchDelayed;
      } else {
        status = NOTIFICATION_STATUS.BatchProcessing;
      }
    }
    return status;
  };
}

export default new NotificationManagerService();
