import { StateService, Transition } from "@uirouter/core";
import { $uiRouter } from "Bootstrap/angular";

function notificationManagerController(
  $transition$: Transition,
  notificationTypes: any[]
) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  const vm = this;
  vm.notificationTypes = notificationTypes;

  /**
   * Called when a user selects a notification version from the sidebar.
   * Sets the selected notification template's parent as 'active' updating the UI.
   * @param {object} version Notification template object as returned from API
   */
  vm.setParentTypeActive = function setParentTypeActive(templateId: number) {
    const templateIdNumber = Number(templateId);
    let parentType: any;

    vm.notificationTypes.forEach((type: any) => {
      type._active = false;
      type.Templates.forEach((it: any) => {
        if (it.ID === templateIdNumber && type.TypeID === it.TypeID) {
          parentType = type;
        }
      });
    });

    if (parentType) {
      parentType._active = true;
    }
  };
  vm.setParentTypeActive(currentStateParams.templateId);
}

notificationManagerController.$inject = ["$transition$", "notificationTypes"];
export default notificationManagerController;
